.App {
  text-align: center;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  display: none;
}
